import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import CustomButton from "../pages/custombutton";



const MegaMenu = (isOpen) => {
  // console.log("isOpen", isOpen);
  const [selectedCategory, setSelectedCategory] = useState("Sales");
  // console.log(selectedCategory);

  function truncateText(text, wordLimit = 15) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  }
  
  return (
    <div>
      {isOpen.isOpen && (
        <div className="absolute left-0 top-full max-w-screen w-full bg-white shadow-lg border-t">
          <div className="flex max-w-full w-full mx-auto px-4 py-8">
            {/* Left Sidebar */}
            <div className="w-1/4 border-r pr-6">
              <div className="relative w-full max-w-md text-xs font-normal mb-6">
                <input
                  className="w-full py-2 pl-10 pr-4 border rounded-sm focus:outline-none focus:ring-1 focus:[#7453E0]"
                  type="text"
                  placeholder="I’m looking for…"
                  autoComplete="off"
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <FaSearch />
                </div>
              </div>
              <h3 className="text-base font-normal">Categories</h3>
              <ul className="mt-4 space-y-2">
                <li
                  className="group hover:text-[#7453E0]"
                  onClick={() => setSelectedCategory("Sales")}
                  onMouseEnter={() => setSelectedCategory("Sales")}
                >
                  <div className="flex items-center justify-between hover:bg-slate-100 font-normal text-sm py-2 pl-2 pr-8 transition-all duration-300">
                    <span>Sales</span>
                    <MdOutlineChevronRight className="transition-transform duration-300 group-hover:translate-x-2" />
                  </div>
                </li>

                <li className="group hover:text-[#7453E0]"
                  onClick={() => setSelectedCategory("Marketing")}
                  onMouseEnter={() => setSelectedCategory("Marketing")}>
                  <div className="flex items-center justify-between hover:bg-slate-100 font-normal text-sm py-2 pl-2 pr-8 transition-all duration-300">
                    <span>Marketing</span>
                    <MdOutlineChevronRight className="transition-transform duration-300 group-hover:translate-x-2" />
                  </div>
                </li>
                <li className="group hover:text-[#7453E0]"
                  onClick={() => setSelectedCategory("Commerce-And-POS")}
                  onMouseEnter={() => setSelectedCategory("Commerce-And-POS")}>
                  <div
                    className="flex items-center justify-between hover:bg-slate-100 font-normal text-sm py-2 pl-2 pr-8 transition-all duration-300"
                  >
                    <span>Commerce and POS</span>
                    <MdOutlineChevronRight className="transition-transform duration-300 group-hover:translate-x-2" />
                  </div>
                </li>
                <li className="group hover:text-[#7453E0]"
                  onClick={() => setSelectedCategory("Service")}
                  onMouseEnter={() => setSelectedCategory("Service")}>
                  <div
                    className="flex items-center justify-between hover:bg-slate-100 font-normal text-sm py-2 pl-2 pr-8 transition-all duration-300"
                  >
                    <span>Service</span>
                    <MdOutlineChevronRight className="transition-transform duration-300 group-hover:translate-x-2" />
                  </div>
                </li>
                <li className="group hover:text-[#7453E0]"
                  onClick={() => setSelectedCategory("Finance")}
                  onMouseEnter={() => setSelectedCategory("Finance")}>
                  <div
                    className="flex items-center justify-between hover:bg-slate-100 font-normal text-sm py-2 pl-2 pr-8 transition-all duration-300"
                  >
                    <span>Finance</span>
                    <MdOutlineChevronRight className="transition-transform duration-300 group-hover:translate-x-2" />
                  </div>
                </li>
              </ul>
              {/* <div className="flex justify-start mt-8 w-full">
                <Link to="/products" className="w-full">
                  <button className="bg-[#7453E0] text-white font-semibold px-4 py-2 rounded-sm flex items-center justify-center w-full gap-2">
                    Explore All Products
                    <MdOutlineChevronRight />
                  </button>
                </Link>
              </div> */}
              <CustomButton to="/products" label="Explore All Products" />




            </div>
            {/* Right Side - Product Grid */}
            <div className="w-3/4 pl-6">
              {selectedCategory === "Sales" ?
                <div>
                  <h3 className="text-xl font-normal">Sales</h3>
                  <div className="grid grid-cols-3 gap-4 mt-4">
                    <Link className="bg-white p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:rounded-lg text-center mx-4 md:mx-0 cursor-pointer hover:scale-105 transition-transform transform" to="/products/sales/inficonnect">
                      <div className="p-4 rounded">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="text-lg font-normal">Infi Connect</h4>
                          <img
                            src="/inficonnectLogoPrevious.png"
                            alt="Product Catalog Illustration"
                            className="w-8 rounded-lg"
                          />
                        </div>
                        <p className="text-xs font-light lg:min-h-16">
                          {truncateText(
                            "A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management."
                          )}
                        </p>
                      </div>
                    </Link>
                    <Link className="bg-white p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:rounded-lg text-center mx-4 md:mx-0 cursor-pointer hover:scale-105 transition-transform transform" to="/products/sales/zeroclick">
                      <div className="p-4 rounded">
                        <div className="flex justify-between items-center mb-2">

                          <h4 className="text-lg font-normal">Zero Click</h4>
                          <img
                            src="/zeroclickLogo.svg"
                            alt="Product Catalog Illustration"
                            className="w-auto h-10 rounded-lg"
                          />
                        </div>
                        <p className="text-xs font-light lg:min-h-16">
                          {truncateText(
                            "Complete loyalty and affiliate management platform."
                          )}
                        </p>
                      </div>
                    </Link>
                    <Link className="bg-white p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:rounded-lg text-center mx-4 md:mx-0 cursor-pointer hover:scale-105 transition-transform transform" to="/products/sales/expressbazaar">
                      <div className="p-4 rounded">
                        <div className="flex justify-between items-center mb-2">

                          <h4 className="text-lg font-normal">Express Bazaar</h4>
                          <img
                            src="/expressbazaarLogo.png"
                            alt="Product Catalog Illustration"
                            className="w-auto h-10 rounded-lg"
                          />
                        </div>
                        <p className="text-xs font-light lg:min-h-16">
                          {truncateText(
                            "Your one-stop online marketplace offering a wide range of products from trusted vendors."
                          )}                          
                        </p>
                      </div>
                    </Link>
                    <Link className="bg-white p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:rounded-lg text-center mx-4 md:mx-0 cursor-pointer hover:scale-105 transition-transform transform" to="/products/sales/qwikco">

                      <div className="p-4 rounded">
                        <div className="flex justify-between items-center mb-2">
                          <h4 className="text-lg font-normal">Qwikco</h4>
                          <img
                            src="/qwikcoLogo.png"
                            alt="Product Catalog Illustration"
                            className="w-auto h-10 rounded-lg"
                          />
                        </div>
                        <p className="text-xs font-light lg:min-h-16">
                          {truncateText(
                            "A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management."
                          )}
                        </p>
                      </div>
                    </Link>

                  </div>
                </div>
                : <div>
                  <p>Coming Soon ...</p>
                </div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MegaMenu;
