// src/components/SignUp.js
import React, { useState } from "react";
import axios from 'axios';
import Layout from "../components/layout";
import { Link, redirect } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { FaLock } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { CountryAndStateData } from "./constant";
import { ToastContainer, toast } from "react-toastify";
import { apiurl } from "../config/config";








const SignUp = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [isStepOneComplete, setIsStepOneComplete] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipcodeValid, setZipcodeValid] = useState(null); // null: not checked, true: valid, false: invalid




  const handleOtpChange = (e) => setOtp(e.target.value);

  const handleStepOneSuccess = () => {
    // Simulate API success
    setIsStepOneComplete(true);
  };
  const handleShowOtpPopup = () => {
    setShowOtpPopup((prev) => !prev);
  };

  const [formData, setFormData] = useState({
    storeName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    country: "IN",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    purpose: "",
    termsAccepted: false,
  });

  console.log("formData", formData)

  const requestData = {
    storeName: formData.storeName,
    // ownerName: "Shubham Sharma",
    ownerName: `${formData.firstName} ${formData.lastName}`,
    ownerEmail: formData.email,
    ownerPhone: formData.phone,
    ownerPassword: formData.password,
    addressDetails: {
      address: formData.address,
      city: formData.city,
      province: formData.state,
      zip: formData.zipcode,
      country: formData.country,
      province_code: "",
      country_code: formData.country
    }
  }


  const countryStates =
    formData.country && CountryAndStateData[formData.country]?.states;
  console.log("countryStates", countryStates)

  // const handleInputChange = (e) => {
  //   setErrorMessage("");
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === "checkbox" ? checked : value,
  //   });
  // };

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value, type, checked } = e.target;

    // Handle phone input with numeric validation
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      if (numericValue.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
      }
      return; // Exit early to avoid executing other logic
    }

    // Handle zipcode validation
    if (name === "zipcode") {
      if (/^[0-9]{0,6}$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        if (value.length === 6) {
          verifyZipcode(value); // Verify zipcode when 6 digits are entered
        } else {
          setZipcodeValid(null); // Reset validation status
        }
      }
      return; // Exit early to avoid executing other logic
    }

    // General input handling for other fields
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const verifyZipcode = async (pincode) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://apps.hulsecure.in/v2/pincode?pincode=${pincode}`);
      console.log("ZipcodeREsponse", response)
      if (response.data && response.data.success === true) {
        setZipcodeValid(true);
      } else {
        setZipcodeValid(false);
      }
    } catch (error) {
      console.error("Error verifying zipcode:", error);
      setZipcodeValid(false);
    } finally {
      setLoading(false);
    }
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // handleShowOtpPopup();
    // handleStepOneSuccess();


    try {
      // Prepare the payload
      const payload = {
        ownerName: `${formData.firstName} ${formData.lastName}`,
        recipientEmail: formData.email,
        storeName: formData.storeName,
      };

      console.log("Payload being sent:", payload);

      // Make the API call
      const response = await axios.post(
        // `https://catalog-pro-engine.onrender.com/admin/v1/otp/generate`,
        `${apiurl}/admin/v1/otp/generate`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("response", response);
      // Handle response
      if (response?.data?.success === "true") {
        toast.success(response?.data?.message || "OTP sent successfully");
        console.log("OTP Modal Opened:", response?.data?.message);
        handleShowOtpPopup();
        handleStepOneSuccess();
      } else {
        toast.error(
          response?.data?.message || "OTP Sent failure, Please try again"
        );
      }
    } catch (error) {
      // Handle and log errors
      console.error(
        "Error generating OTP:",
        error.response?.data || error.message
      );
      toast.error(
        "An error occurred while sending OTP. Please try again later."
      );
    }
  }
  const handleOtpSubmit = async (e) => {
    

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      // url: 'https://catalog-pro-engine.onrender.com/api/vendor/create',
      url: `${apiurl}/api/vendor/create`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(requestData)
    };

    try {
      const response = await axios.request(config);
      console.log('Response:', (response?.data?.message));
      console.log('Response success:', (response?.data?.success));
      if (response?.data?.success === true) {
        navigate("/thankyou");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Layout>

      <div className={`${showOtpPopup ? "flex gap-8 justify-center md:px-8" : "flex gap-8 justify-center min-h-screen md:px-8"}`}>
        {/* <div className="bg-slate-950 hidden lg:flex flex-col justify-between align-middle p-6 md:p-10 w-1/2 my-9 text-white">
          <div className="w-[100%] h-[50%] flex flex-col justify-evenly">
            <img src="/inficonnect/inficonnectLogo.png" alt="SignupImage" className="w-[20%] mx-auto"></img>
            <h1 className="text-2xl md:text-4xl font-bold text-center">Welcome to Inficonnect! Building the future of digital experiences.</h1>
            <p className="px-16 text-center text-slate-300">Inficonnect is your one-stop platform for building meaningful connections between vendors and brands. Whether you're a vendor looking to showcase your products to top brands or a brand seeking reliable vendors to boost your supply chain, Inficonnect makes it effortless and efficient.</p>
          </div>
          <div className="w-[100%] h-[50%] flex items-center justify-center">
            <img src="/signupImageBlackandwhite.png" alt="SignupImage" className="w-[70%] h-auto"></img>
          </div>
        </div> */}
        <div
          className={`${showOtpPopup
            ? "relative overflow-hidden top-[20%] left-0 w-[35%] p-6 max-h-screen bg-gray-50 lg:h-[75vh] hidden lg:flex flex-col"
            : "relative overflow-hidden lg:sticky top-[20%] left-0 w-[35%] p-6 max-h-screen bg-gray-50 lg:h-[75vh] hidden lg:flex flex-col"
            }`}
        >
          {/* Logo */}
          <div className="mb-20">
            <h1 className="text-lg font-bold text-gray-400">INFICONNECT</h1>
          </div>

          {/* Steps */}
          <div className="space-y-6 relative">
            {/* Step 1 */}
            <div
              className={`flex relative z-20 items-center gap-4 ${isStepOneComplete ? "opacity-50" : "text-black"
                }`}
            >
              <div className="flex items-center justify-center w-10 h-10 bg-white border border-gray-200 rounded-full">
                <FaUserAlt size={18} />
              </div>
              <div>
                <p className="font-medium">Fill the form details</p>
                <p className="text-sm text-gray-500">Provide your details</p>
              </div>
            </div>
            <div className="h-[50%] w-[2px] bg-gray-200 absolute left-4 top-0 z-10" />

            {/* Step 2 */}
            <div
              className={`flex relative z-20 items-center gap-4 ${isStepOneComplete ? "text-black" : "opacity-50"
                }`}
            >
              <div
                className={`flex items-center justify-center w-10 h-10 border rounded-full ${isStepOneComplete
                  ? "bg-black text-white"
                  : "bg-white border-gray-200"
                  }`}
              >
                <FaLock size={18} />
              </div>
              <div>
                <p className="font-medium">Enter OTP</p>
                <p className="text-sm text-gray-500">Verify your identity</p>
              </div>
            </div>
          </div>
          <img src="/inficonnectLogoGrey.png" className="absolute -right-[5%] bottom-[25%] w-52 transform -rotate-45" />

          {/* Buttons */}
          <div className=" absolute bottom-5 left-5 mt-8 flex justify-between border-1 hover:border-slate-600  border border-1 border-slate-300 hover:border-slate-600 px-4 py-2 rounded-md">
            <Link to="/products/sales/inficonnect">
              <button
                className="text-slate-500 text-sm"
                onClick={() => setIsStepOneComplete(false)}
              >
                Back
              </button>
            </Link>
          </div>
          <div className=" absolute bottom-5 right-5 mt-8 flex justify-between  border border-1 border-slate-300 hover:border-slate-600 px-4 py-2 rounded-md">
            <button
              className="text-slate-600 text-sm"
            >
              Sign in
            </button>

          </div>
        </div>


        {/* <div className="bg-slate-950 hidden lg:flex flex-col justify-between align-middle p-6 md:p-10 w-1/2 my-9 text-white">
          <div className="w-[100%] h-[50%] flex flex-col justify-evenly">
            <img src="/inficonnect/inficonnectLogo.png" alt="SignupImage" className="w-[20%] mx-auto"></img>
            <h1 className="text-2xl md:text-4xl font-bold text-center">Welcome to Inficonnect! Building the future of digital experiences.</h1>
            <p className="px-16 text-center text-slate-300">Inficonnect is your one-stop platform for building meaningful connections between vendors and brands. Whether you're a vendor looking to showcase your products to top brands or a brand seeking reliable vendors to boost your supply chain, Inficonnect makes it effortless and efficient.</p>
          </div>
          <div className="w-[100%] h-[50%] flex items-center justify-center">
            <img src="/signupImageBlackandwhite.png" alt="SignupImage" className="w-[70%] h-auto"></img>
          </div>
        </div> */}

        {showOtpPopup ?
          <div className="relative md:rounded-lg w-full h-screen lg:h-auto max-h-screen lg:w-[65%] p-8 ">
            <div className="absolute w-full flex border border-1 border-slate-100 flex-col items-center max-w-md p-6 bg-white rounded shadow-sm top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <button
                onClick={() => {
                  handleShowOtpPopup();
                  setIsStepOneComplete(false);
                }}
                className="absolute top-2 right-2 text-sm flex items-center justify-center w-6 h-6 rounded-sm border border-gray-400 bg-white text-gray-400 hover:border-gray-600 hover:text-gray-600 transition-all duration-200"
              >
                <IoClose />
              </button>

              <img src="/verificationPhone.png" className="w-52" />

              <h2 className="mb-4 text-xl ont-semibold tracking-wide text-center">
                Verify Phone
              </h2>
              <p className="mb-4 text-center text-gray-600">
                Enter the OTP sent to your phone.
              </p>
              <input
                type="text"
                value={otp}
                onChange={handleOtpChange}
                placeholder="Enter OTP"
                className="w-full px-4 py-2 mb-4 border rounded focus:outline-none focus:ring-1 focus:ring-green-500"
              />
              <button
                className="w-full px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                onClick={handleOtpSubmit}
              >
                Submit OTP
              </button>
            </div>
          </div> :
          <div className="md:rounded-lg w-full lg:w-[65%] p-8 overflow-y-auto">
            <h2 className="text-2xl md:text-3xl text-slate-950 font-bold text-center mb-6">Sign Up</h2>
            <form onSubmit={handleFormSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="md:col-span-2">
                <label className="block text-slate-600 mb-2">Store Name</label>
                <input
                  type="text"
                  name="storeName"
                  value={formData.storeName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your Store name"
                  required
                />
              </div>
              {/* First Name */}
              <div>
                <label className="block text-slate-600 mb-2">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your first name"
                  required
                />
              </div>

              {/* Last Name */}
              <div>
                <label className="block text-slate-600 mb-2">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your last name"
                  required
                />
              </div>

              {/* Email */}
              <div>
                <label className="block text-slate-600 mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Phone */}
              <div>
                <label className="block text-slate-600 mb-2">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              {/* Password */}
              <div>
                <label className="block text-slate-600 mb-2">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                    placeholder="Enter your password"
                    required
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                  >
                    {showPassword ? "🙈" : "👁️"}
                  </span>
                </div>
              </div>

              {/* Confirm Password */}
              <div>
                <label className="block text-slate-600 mb-2">Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Confirm your password"
                  required
                />
              </div>

              {/* Country */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:col-span-2">
                <div>
                  <label className="block text-slate-600 mb-2">Country</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                    required
                  >
                    <option value="" disabled>Select your Country</option>
                    <option value="IN">India</option>
                  </select>
                </div>
                <div>
                  <label className="block text-slate-600 mb-2">Zipcode</label>
                  <input
                    type="text"
                    name="zipcode"
                    value={formData.zipcode}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-3 border ${zipcodeValid === false
                      ? "border-red-500 focus:ring-red-500"
                      : zipcodeValid === true
                        ? "border-green-500 focus:ring-green-500"
                        : "border-gray-300 focus:ring-slate-400"
                      } focus:outline-none focus:ring-2`}
                    placeholder="Zipcode"
                    required
                  />
                  {loading && <p className="text-sm text-blue-500">Verifying zipcode...</p>}
                  {zipcodeValid === true && <p className="text-sm text-green-500">Zipcode is valid!</p>}
                  {zipcodeValid === false && <p className="text-sm text-red-500">Invalid zipcode. Please check.</p>}
                </div>

              </div>

              {/* Address */}
              <div className="md:col-span-2">
                <label className="block text-slate-600 mb-2">Address</label>
                <textarea
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your address"
                  required
                />
              </div>

              {/* City, State, Zipcode */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:col-span-2">
                {/* <div>
                  <label className="block text-slate-600 mb-2">State</label>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                    placeholder="State"
                    required
                  />
                </div> */}
                <div className="">
                  <label className="block text-slate-600 mb-2">State</label>
                  {countryStates ? (
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-slate-400"
                      required
                    >
                      <option value="">Select State</option>
                      {Object.entries(countryStates).map(([stateCode, stateName]) => (
                        <option key={stateCode} value={stateCode}>
                          {stateName}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p className="text-gray-500">Select a country to see states</p>
                  )}
                </div>
                <div>
                  <label className="block text-slate-600 mb-2">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                    placeholder="City"
                    required
                  />
                </div>


              </div>

              {/* Purpose */}
              <div className="md:col-span-2">
                <label className="block text-slate-600 mb-2">Purpose</label>
                <select
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300   focus:outline-none focus:ring-2 focus:ring-slate-400"
                  required
                >
                  <option value="" disabled>Select your purpose</option>
                  <option value="personal">Personal Use</option>
                  <option value="business">Business Use</option>
                  <option value="other">Other</option>
                </select>
              </div>


              {/* Terms and Conditions */}
              <div className="flex items-center md:col-span-2">
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleInputChange}
                  className="mr-2"
                  required
                />
                <label className="text-slate-600">I agree to the terms and conditions</label>
              </div>

              {errorMessage !== "" ? <p className="text-red-500">{errorMessage}</p> : null}

              {/* Submit Button */}
              <div className="flex items-center md:col-span-2">

                <div className="w-full flex justify-center mt-4">
                  <button
                    type="submit"
                    className="w-2/4 md:w-3/4 max-w-xs text-sm md:text-base bg-slate-800 text-white py-3   hover:bg-slate-900 transition duration-300"
                  // onClick={handleSubmit}

                  >
                    Sign Up
                  </button>
                </div>
              </div>

            </form>
          </div>
        }


      </div>


    </Layout>
  );
}

export default SignUp;